import { Content, isFilled } from '@prismicio/client';
import {
  JSXMapSerializer,
  PrismicRichText,
  SliceComponentProps,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { PrismicNextLink as CustomPrismicNextLink } from '@/components/Internal/Button';
import { ArrowRightV2 } from '@/components/Unknown/Icons';
import { PrismicNextLink } from '@prismicio/next';
import { useRouter } from 'next/router';
import styles from './serviceCardsStyles.module.scss';

/**
 * Props for `ServiceCards`.
 */
export type ServiceCardsProps = SliceComponentProps<Content.ServiceCardsSlice>;

/**
 * Component for "ServiceCards" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl md:text-center">
      {children}
    </h2>
  ),
  heading3: ({ children }) => (
    <h3 className="text-balance font-bold ~lg:~text-arc-xl/arc-2xl xl:min-h-[90px]">
      {children}
    </h3>
  ),
  paragraph: ({ children }) => (
    <p className="mx-0 max-w-prose text-pretty font-normal ~lg:~text-arc-base/arc-2xl md:mx-auto md:text-center">
      {children}
    </p>
  ),
};

const hoverColorMap = {
  'Light Indigo': {
    text: 'group-hover:text-night-blue',
    bg: 'group-hover:bg-brand-color-light',
  },
  Fern: {
    text: 'group-hover:text-night-blue',
    bg: 'group-hover:bg-fern',
  },
  Citrus: {
    text: 'group-hover:text-night-blue',
    bg: 'group-hover:bg-citrus',
  },
  Watermelon: {
    text: 'group-hover:text-night-blue',
    bg: 'group-hover:bg-watermelon',
  },
};

const ServiceCards = ({ slice }: ServiceCardsProps): JSX.Element => {
  const router = useRouter();
  const handleCardClick = (link: string, isNewTab: boolean = false) => {
    if (isNewTab) {
      window.open(link, '_blank');
      return;
    }
    router.push(link);
  };
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="px-0 o-6 md:px-16">
          <PrismicRichText
            field={slice.primary.heading}
            components={component}
          />
          {slice.primary.show_sub_heading && (
            <PrismicRichText
              field={slice.primary.sub_heading}
              components={component}
            />
          )}
        </div>
        <ul className="mt-20 flex flex-col gap-4 xl:flex-row xl:gap-6">
          {slice.primary.services.map((item, index) => (
            <li key={index} className="group flex-1">
              <article
                onClick={() => {
                  handleCardClick(
                    (isFilled.link(item.button_link) && item.button_link.url) ||
                      '/',
                    isFilled.link(item.button_link) &&
                      item.button_link.link_type === 'Web' &&
                      item.button_link.target === '_blank',
                  );
                }}
                className={`flex h-full cursor-pointer select-none flex-col items-start justify-between gap-24 rounded-[20px] px-8 py-10 shadow-arc-default will-change-auto xl:rounded-2xl ${hoverColorMap[item.hover_color].bg} ${hoverColorMap[item.hover_color].text} transition-colors`}
              >
                <div className="w-full o-6 xl:w-auto">
                  <div className="flex items-center justify-between xl:block">
                    <PrismicRichText
                      field={item.heading}
                      components={component}
                    />
                    <PrismicNextLink
                      field={item.button_link}
                      className={`${styles['arrow-cta-button']} group-hover:text-current`}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ArrowRightV2 />
                    </PrismicNextLink>
                  </div>
                  <PrismicRichText
                    field={item.content}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="mx-auto text-pretty text-base font-normal sm:~lg:~text-arc-base/arc-lg xl:max-w-prose">
                          {children}
                        </p>
                      ),
                    }}
                  />
                </div>
                <CustomPrismicNextLink
                  field={item.button_link}
                  endIcon={<ArrowRightV2 />}
                  variant="text"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className={`${styles['main-cta-button']} group-hover:text-current`}
                >
                  {item.button_label}
                </CustomPrismicNextLink>
              </article>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default ServiceCards;
